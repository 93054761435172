
.create-room {
  width: 50%;
  --background: var(--ion-color-secondary-shade);
  --background-activated: var(--ion-color-secondary);
  --background-focused: var(--ion-color-secondary);
}

.intro-col {
  color: var(--ion-color-dark);
}

.intro-header {
  font-size: 36px;
  margin-top: 40px;
}

.support-col {
  margin-top: 20px;
}

.request-col {
  margin-top: 30px;
  color: var(--ion-color-dark);
}

.home-content {
  --background: var(--ion-color-light-shade);
}

.home-grid {
  font-family: 'custom-head';
  max-width: 800px;
  font-size: 26px;
  color: var(--ion-color-secondary);
  text-align: center;
}
.footer-grid {
  font-family: 'custom-head';
  max-width:1200px;
  font-size: 16px;
  color: var(--ion-color-primary);
  text-align: center;
}

ion-toolbar {
  text-align: center;
}



ion-title {
  font-size: 28px;
  font-style: oblique;
  color: var(--ion-color-primary);
}

.first-step-col {
  margin-top: 30px;
}

.create-col {
  margin-bottom: 20px;
}

.share-col {
  margin-top: 2px;
  font-size: 20px;
  margin-bottom: 50px;
}

.join-col {
  font-size: 20px;
}

.paste-col {
  padding: 0;
  margin-bottom: 50px;
}

.paste-toolbar {
  max-width: 300px;
  --background: none;
  margin: auto;
}

.paste-input {
  margin-left: 4px;
  text-align: left;
  font-size: 14px;
  border: 1px solid var(--ion-color-secondary);
  border-radius: 5px;
}

.paste-button {
  margin-right: 2px;
  --box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ion-col {
  /* background-color: #d4521f;
  max-width: 800px;
  /* border: solid 1px #fff; */
  /* color: #fff; */
  /* text-align: center; */
  /* padding-right: 20px; */ 
  font-size: 12px;
}


.settings-header {
  text-align: left;
}

.name-item {
  font-size: 14px;
  color: var(--ion-color-secondary);
}

.name-input {
  color: #fff;
}

.error-message {
  padding-top: 4px;
  padding-left: 16px;
  color: #e61a61;
}

.about-grid {
  margin-top: 20px;
  font-size: 15px;
  font-style: bold;
  color: var(--ion-color-secondary);
  text-align: center;
  
}

.externals-row {
  margin-top: 25px
   
}

.about-icons {
  width: 40px;
  height: 40px;
  padding: 0;
  top: 0;
  cursor: pointer;
  color: var(--ion-color-secondary);
}
.logo-center
{
  width: 250px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  
}

.ion-content-scroll-host {
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  overflow-y: auto;
}


/* loading... */