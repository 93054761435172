.message-content {
  overflow-y: auto;
  height: calc(100% - 162px);
}

.message-grid {
  margin-right: 0;
  height: 100%;
  user-select: text;
}

.right-align {
  justify-content: flex-end;
}

.my-msg {
  padding: 6px;
  border-radius: 5px;
  margin: 2px 0 2px 0;
  max-width: 70%;
  background: var(--ion-color-secondary-shade);
  color: #fff;
}

.other-msg {
  padding: 6px;
  border-radius: 5px;
  margin: 2px 0 2px 0;
  max-width: 70%;
  background: var(--ion-color-primary-shade);
  color: #fff;
}

.system-msg {
  margin: auto;
  color: #999;
  font-size: 13px;
  padding: 4px;
}

.message-toolbar {
  padding-left: 5px;
  padding-top: 50px;
  --background: var(--ion-color-light);
  border-top: 1px solid #777;
  
}

.footer-ios ion-toolbar:first-of-type {
  padding-top: 5px;
}

.message-input {
  width: 100%;
  height: 100%;
  border: 1px solid var(--ion-color-secondary);
  border-radius: 5px;
  text-align: left;
}

.send-button {
  align-self: center;
  margin: 0 5px 0 5px;
  --box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 576px) {
  .message-input {
    border-radius: 10px;
  }

  .native-input.sc-ion-input-ios {
    padding-inline-start: 5px;
  }
}
