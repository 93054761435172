.room-grid {
  background: var(--ion-color-light-shade);
  margin-inline-start: 0;
  margin-inline-end: 0;
  height: 100%;
  padding: 0;
}

@media (min-width: 992px) {
  .player-col {
    height: calc(0.5625 * 75vw);
   
  }
}

@media (max-width: 992px) {
  .player-col {
    height: 35%;
  }
}
  
.room-row {
  height: 100%;
}

.frame-col {
  padding: 0;
}

@media (max-width: 992px) {
  .frame-col {
    height: 65%;
  }
}

