.frame-card {
  background: var(--ion-color-light);
  height: 100%;
  width: 100%;
  float: right;
  margin: 0;
}

.frame-segment {
  background: none;
}

ion-segment-button {
  --indicator-transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 40px;
}

ion-fab-button {
  --background: var(--ion-color-secondary-shade);
  --background-activated: var(--ion-color-secondary);
}

ion-list-header {
  color: var(--ion-color-primary);
  font-size: 20px;
  border-bottom: 1px solid
    var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}
