
.search-card-content{
    text-wrap: wrap;
    max-width: 51%;
    color: #fff;
}
.search-card-content h4{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.search-card-content p{
    line-height: 1.6;
    margin-top: 0.5rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
}
.search-card-content h5{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.img-container{
    position: relative;
}

.video-duration{
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: black;
    color: white;
    padding: 6px 10px;
}


@media only screen and (max-width: 1500px) {

    .card_container{
        
        display: flex;
        flex-direction: column;
    }

    .img-container img{
        width: 100%;
    }

    .search-card-content{
        flex: 1;

        max-width: 100%;
    }
    
}


@media only screen and (max-width: 992px) {

    .card_container{
        flex-direction: row;
    }

    
}
@media only screen and (max-width: 600px) {

    .img-container{
        width: 35%;
    }

}

.name-input  {
  border-radius: 5px;
  border: 1px solid var(--ion-color-secondary);
  max-width: 360px;
  text-align: left;
}
.search-toolbar {
    padding-left: 5px;
    --background: var(--ion-color-light);
    border-top: 1px solid #777;
  }

  .search-btn {
    width: 50%;
    --background: var(--ion-color-secondary-shade);
    --background-activated: var(--ion-color-secondary);
    --background-focused: var(--ion-color-secondary);
    margin-left: 90px;
  }
  .search-input {
    width: 100%;
    height: 100%;
    border: 1px solid var(--ion-color-secondary);
    border-radius: 5px;
    text-align: left;
  }
  .search-heading {
    
    margin-left: 30px;
  }
  
