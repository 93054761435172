.online-content {
  height: calc(100% - 162px + 52px);
}

.online-list {
  padding-top: 0;
  padding-bottom: 0;
}

.online-item {
  --padding-start: 12px;
  --min-height: 30px;
  color: var(--ion-color-secondary);
}

.online-label {
  margin-top: 0;
  margin-bottom: 0;
}

.clipboard-col {
  position: absolute;
  bottom: 0;
  padding: 0;
}

.clipboard-toolbar {
  max-width: 428px;
  margin-left: 12px;
  padding-right: 16px;
  height: 56px;
  display: flex;
  align-items: center;
  --padding-start: 0;
}

.clipboard-input {
  border-radius: 5px;
  border: 1px solid var(--ion-color-secondary);
  max-width: 360px;
  text-align: left;
}

.settings-header {
  text-align: left;
}

.name-item {
  font-size: 14px;
  color: #098572;
}

.name-input  {
  border-radius: 5px;
  border: 1px solid var(--ion-color-secondary);
  max-width: 360px;
  text-align: left;
}

.error-message {
  padding-top: 4px;
  padding-left: 16px;
  color: #e61a61;
}

.about-grid {
  margin-top: 20px;
  font-size: 20px;
  font-style: bold;
  color: var(--ion-color-secondary);
  text-align: center;
}

.externals-row {
  margin-top: 25px;
}

.about-icons {
  width: 40px;
  height: 40px;
  padding: 0;
  top: 0;
  cursor: pointer;
  color: var(--ion-color-secondary);
}
.save-button{
  background-color: #098572;
  border: none;
  color: white;
  
  text-align: center;
  text-decoration: none;
  
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  
   
}
.name-toolbar {
  padding-left: 0px;
  --background: var(--ion-color-light);
 
}