.extension-content {
  --background: var(--ion-color-light-shade);
}

.extension-content * {
  color: var(--ion-color-primary);
  font-size: 20px;
}

.ext-link {
  display: inline-block;
}

.firefox-ext-img {
  height: 60px;
  width: 172px;
  padding-left: 10px;
}

.chrome-ext-img {
  height: 62px;
  width: 220px;
  padding-left: 10px;
}
